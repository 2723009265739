


















































































































































import moment from 'moment';
import Vue from 'vue';
import Moment from '~/components/common/Moment.vue';
import {Question} from '~/models';

export default Vue.extend({
  name: 'TestDetailPage',
  components: {Moment},
  data() {
    return {
      dialog: {show: false, question: null},
      img: {show: false, path: null},
      imgDialog: {show: false, figures: null},
      testInstanceResponse: null,
      instance: null,
      answers: null,
      correctAnswers: null,
    };
  },
  async created() {
    this.instance = this.$store.state.practice.testInstance;
    this.answers = Object.assign({}, this.$store.state.practice.answers);
    this.correctAnswers = Object.assign({}, this.$store.state.practice.correctAnswers);
    await this.$store.dispatch('practice/clearTest');
  },
  computed: {
    correctAnswerCount(): number {
      return this.instance.questions.filter(q => this.isCorrect(q)).length;
    },
    duration(): string {
      const diff = moment(this.instance.finishedAt).diff(this.instance.createdAt);
      return moment.utc(diff).format('HH:mm:ss');
    },
    percentage(): number {
      return Math.floor(this.correctAnswerCount / this.instance.questionAmount * 100);
    },
  },
  methods: {
    getCorrectAnswer(question: Question) {
      const correctAnswerId = this.correctAnswers[question.id];
      return question.answers.find(a => Number(a.id) === Number(correctAnswerId));
    },
    getProvidedAnswer(question: Question) {
      const providedAnswerId = this.answers[question.id];
      return question.answers.find(a => Number(a.id) === Number(providedAnswerId));
    },
    getCorrectAnswerText(question: Question) {
      return this.getCorrectAnswer(question)?.text;
    },
    getProvidedAnswerText(question: Question) {
      return this.getProvidedAnswer(question)?.text;
    },
    isCorrect(question: Question): boolean {
      return this.getProvidedAnswer(question) && this.getCorrectAnswer(question)?.id === this.getProvidedAnswer(question)?.id;
    },
  },
});
